import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import GlobalLayout from "../layouts/globalLayout";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SectionHeader from "../components/SectionHeader/sectionHeader";
import * as styles from './about.module.css';

const imagesQuery = graphql`{
  rainbowPeach: file(relativePath: {eq: "icons/loveShackRainbowPeach.png"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  },
  caseyJulieTogetherPic: file(relativePath: {eq: "about/firstPhoto.jpg"}) {
    childImageSharp {
      gatsbyImageData(height: 350)
    }
  },
  casey: file(relativePath: {eq: "about/Casey.png"}) {
    childImageSharp {
      gatsbyImageData(height: 350)
    }
  },
  julie: file(relativePath: {eq: "about/Julie.png"}) {
    childImageSharp {
      gatsbyImageData(height: 350)
    }
  },
  flowers: file(relativePath: {eq: "about/flowers.jpg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
    }
  }
}`;

export default function About() {
  const images = useStaticQuery(imagesQuery);

  return (
    <GlobalLayout>
      <div className={styles.headerDescriptionContainer}>
        <div className={styles.headerDescriptionTitle}>ABOUT LOVE SHACK</div>
        <div className={styles.headerDescriptionDesc}>we are a groovy mother-daughter duo that loves to curate and preserve vintage items for your everyday life as well as your magical celebrations</div>
      </div>
      <div className={styles.howItStartedContainer}>
        <div className={styles.rainbow}>
          <GatsbyImage
            image={images.rainbowPeach.childImageSharp.gatsbyImageData}
            style={{
              height: "100%",
              width: "auto",
              margin: "auto"
            }}
          />
        </div>
        <div className={styles.howItStartedPic}>
          <GatsbyImage
            image={images.caseyJulieTogetherPic.childImageSharp.gatsbyImageData}
            style={{
              height: "100%",
              width: "auto",
              margin: "auto"
            }}
          />
        </div>
        <div className={styles.howItStartedDescContainer}>
          <div className={styles.howItStartedDescSubContainer}>
            <div className={styles.howItStartedTitle}>
              HOW IT ALL STARTED
            </div>
            <div className={styles.howItStartedDesc}>
              {`It all began back in 2018 when Casey & Devin got engaged and the wedding planning was in full swing. Casey enlisted the help of her beloved mother, Julie, to help with details of her special day. Casey wanted a vintage inspired boho wedding and loved the idea of incorporating vintage glassware into her tablescapes. Julie took on the big job of finding the vintage items for the wedding. She went to estate sales and antique stores, you name it she scoured through every piece of glassware and furniture. Once the wedding day came and went, Julie was continuing to hunt for vintage treasure, she couldn't stop. Her collection was growing and growing and growing. She wanted to share her vintage items with others so this time she enlisted her beloved daughter, Casey, to help. Love Shack Vintage was born.`}
            </div>
            <Link to="/services/" className={styles.howItStartedLink}>
              VIEW OUR SERVICES
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.momCaseyBioContainer}>
        <Grid container className={styles.momCaseyBioSubContainer}>
          <Grid item sm={5} xs={12} className={styles.personOuterContainer}>
            <div className={styles.personPic}>
              <GatsbyImage
                image={images.casey.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className={styles.personTitle}>
              Casey
            </div>
            <div className={styles.personSubTitle}>
              LOVE POTION AFICIONADO
            </div>
            <div className={styles.personDescription}>
              She is the creative spirit with an eye for detail that makes love shack sparkle. Casey can take anything ordinary and turn it into something extraordinary. She likes finding new restaurants, exploring little towns and her outfit always matches the adventure. 
            </div>
          </Grid>
          <Grid item xs={2}/>
          <Grid item sm={5} xs={12} className={styles.personOuterContainer}>
            <div className={styles.personPic}>
              <GatsbyImage
                image={images.julie.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className={styles.personTitle}>
              Julie
            </div>
            <div className={styles.personSubTitle}>
              DISCO DANCING QUEEN
            </div>
            <div className={styles.personDescription}>
              She is the finder of lost treasures, the lover of color, and motivator behind Love Shack. Julie enjoys working beside her daughter to bring Love Shack to your everyday and magical celebrations. You’ll find her hiking, roller skating, and sewing the day away.
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.linkBoxContainer}>
        <div className={styles.linkBoxSubContainer}>
          <div className={styles.linkBoxTextAndPic}>
            <div className={styles.linkBoxText}>
              VINTAGE WITH A TOUCH OF MAGIC
            </div>
            <div className={styles.linkBoxPic}>
              <GatsbyImage 
                image={images.flowers.childImageSharp.gatsbyImageData}
                // style={{ width: 'auto', height: '90%', left: '12px' }}
              />
            </div>
          </div>
          <div className={styles.linkBoxLinks}>
            <Link to="/rentals/" className={styles.vintageButtonLink}>
              <Button className={styles.vintageButton} variant="outlined">BROWSE OUR RENTAL PIECES</Button>
            </Link>
            <Link to="/shop/" className={styles.vintageButtonLink}>
              <Button className={styles.vintageButton} variant="outlined">VISIT THE LOVE SHACK SHOP</Button>
            </Link>
            <Link to="/services/" className={styles.vintageButtonLink}>
              <Button className={styles.vintageButton} variant="outlined">VIEW OUR RECENT PROJECTS</Button>
            </Link>
            <Link to="/blog/" className={styles.vintageButtonLink}>
              <Button className={styles.vintageButton} variant="outlined">READ OUR GROOVY BLOG</Button>
            </Link>
          </div>
        </div>
      </div>
    </GlobalLayout>
  );
}

function PersonPicAndDescription({
  personName,
  personTitle,
  personDescription,
  personPic,
  height,
  width
}) {
  return (
    <div className={styles.person__row}>
      <div className={styles.person__pic}>
        <div className={styles.pic__container}>
          <GatsbyImage
            image={personPic.childImageSharp.gatsbyImageData}
            style={{
              overflow: "hidden",
              width: width ? width : "100%",
              height: height ? height : "180px",
              borderRadius: '25px'
            }}
            objectPosition="left"
            imgStyle={{ objectFit: "contain" , objectPosition: 'center'}} />
        </div>
        <div className={styles.person__title}>
          {personName}
          <br />
          <div className={styles.person__company__title}>
            {personTitle}
          </div>
        </div>
      </div>
      <div className={styles.person__description}> 
          {personDescription}
      </div>
    </div>
  );
}