// extracted by mini-css-extract-plugin
export var headerDescriptionContainer = "about-module--headerDescriptionContainer--1xpwT";
export var headerDescriptionTitle = "about-module--headerDescriptionTitle--f4VmE";
export var headerDescriptionDesc = "about-module--headerDescriptionDesc--HzgeE";
export var howItStartedContainer = "about-module--howItStartedContainer--1V_ub";
export var rainbow = "about-module--rainbow--3yBqS";
export var howItStartedPic = "about-module--howItStartedPic--cSqzX";
export var howItStartedDescContainer = "about-module--howItStartedDescContainer--1MFhJ";
export var howItStartedDescSubContainer = "about-module--howItStartedDescSubContainer---nrtL";
export var howItStartedTitle = "about-module--howItStartedTitle--TlAfG";
export var howItStartedDesc = "about-module--howItStartedDesc--22Upg";
export var howItStartedLink = "about-module--howItStartedLink--1Lb_O";
export var momCaseyBioContainer = "about-module--momCaseyBioContainer--16EIy";
export var momCaseyBioSubContainer = "about-module--momCaseyBioSubContainer--3HUNg";
export var personOuterContainer = "about-module--personOuterContainer--27a9n";
export var personPic = "about-module--personPic--1hjC4";
export var personTitle = "about-module--personTitle--1u4Os";
export var personSubTitle = "about-module--personSubTitle--Ac8V2";
export var personDescription = "about-module--personDescription--1MCnb";
export var linkBoxContainer = "about-module--linkBoxContainer--3TqJn";
export var linkBoxSubContainer = "about-module--linkBoxSubContainer--204qM";
export var linkBoxTextAndPic = "about-module--linkBoxTextAndPic--tYajA";
export var linkBoxText = "about-module--linkBoxText--1PS-9";
export var linkBoxPic = "about-module--linkBoxPic--3UWUI";
export var linkBoxLinks = "about-module--linkBoxLinks--2wHW4";
export var vintageButtonLink = "about-module--vintageButtonLink--1994m";
export var vintageButton = "about-module--vintageButton--1KCvt";