import React from "react";
import * as styles from "./sectionHeader.module.css";
import clsx from "clsx";

export default function SectionHeader(props) {
  const { title, light, extraTitles, className } = props;

  return (
    <div className={clsx(styles.container, { [styles.font__color__light]: light }, className)}>
      {title}
      {extraTitles &&
        extraTitles.map((text) => (
          <>
            <br></br>
            {text}
          </>
        ))}
    </div>
  );
}
